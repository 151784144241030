import React from "react";

// Customizable Area Start
import {
  Modal,
  Container,
  Box,
  Button,
  Typography,
  Input,
  Grid,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Avatar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  CircularProgress,
} from "@material-ui/core";
import { createTheme, styled } from "@material-ui/core/styles";
import VolumeOffIcon from "@material-ui/icons/VolumeOff";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MailOutlineOutlinedIcon from "@material-ui/icons/MailOutlineOutlined";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import SendRoundedIcon from "@material-ui/icons/SendRounded";
import MicNoneOutlinedIcon from "@material-ui/icons/MicNoneOutlined";
import MicOffOutlinedIcon from "@material-ui/icons/MicOffOutlined";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
import SideNavbar from "../../../components/src/SideNavbar.web";
import Sidebar from "../../../components/src/ChatNavBar";
import { callIcon, notiIcon } from "./assets";
import { ChatItem } from "../../../components/src/MessageItem";
import { UserChat } from "../../../components/src/UserChat";

export const PasswordDialogStyle = styled(Box)({
"& .dialog-header": {
    textAlign: "center",
    padding: "16px 24px",
    backgroundColor: "#f5f5f5",
  },
  "& .dialog-title": {
    fontSize: "24px",
    fontWeight: 600,
    fontFamily: "Jost",
    color: "#333",
  },
  "& .dialog-message": {
    textAlign: "center",
    fontSize: "16px",
    fontWeight: 400,
    fontFamily: "Jost",
    color: "#555",
    margin: "16px 0",
  },
  "& .dialog-actions": {
    display: "flex",
    justifyContent: "space-between",
    padding: "16px 24px",
  },
  "& .accept-btn": {
    backgroundColor: "#4CAF50",
    color: "#FFF",
    textTransform: "capitalize",
    fontFamily: "Jost",
    fontWeight: 600,
    borderRadius: "4px",
    padding: "12px 24px",
    "&:hover": {
      backgroundColor: "#45A045",
    },
  },
  "& .reject-btn": {
    backgroundColor: "#F44336",
    color: "#FFF",
    textTransform: "capitalize",
    fontFamily: "Jost",
    fontWeight: 600,
    borderRadius: "4px",
    padding: "12px 24px",
    "&:hover": {
      backgroundColor: "#E53935",
    },
  },
});

// Customizable Area End

import ChatController, { configJSON, IChat, Props } from "./ChatController";

export default class Chat extends ChatController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderLeftChatList = () => {
    return (
      <Grid
        style={{
          background: "white",
          height: "100%",
          overflow: "scroll",
          scrollbarWidth: "none",
          borderRight: "1px solid lightgray",
        }}
        item
        xs={4}
      >
        {/* message */}
        <Grid
          item
          xs={12}
          style={{
            marginBottom: 32,
            marginLeft: 16,
            marginRight: 16,
            marginTop: 30,
          }}
        >
          <span
            style={{
              fontFamily: "Jost",
              fontSize: "40px",
              lineHeight: "50px",
              fontWeight: 700,
              color: "#1F3650",
            }}
          >
            Messages
          </span>
        </Grid>
        {/* Search */}
        <Grid item xs={12} style={{ display: "grid", marginBottom: 16 }}>
          <TextField
            style={{ marginLeft: 16, marginRight: 16 }}
            id="outlined-basic"
            variant="outlined"
            placeholder="Search"
          />
        </Grid>

        {/* Accordion */}
        <Grid
          item
          xs={12}
          style={{ display: "grid", marginLeft: 0, marginRight: 0 }}
        >
          <Accordion style={{ boxShadow: "none" }} expanded={this.state.unreadAccordion} onChange={this.onUnreadChange}>
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon style={{ color: "rgba(192, 192, 192, 0.8)" }} />
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
              style={{ paddingLeft: 16, paddingRight: 16 }}
            >
              <MailOutlineOutlinedIcon
                style={{ marginRight: 8, color: "rgba(192, 192, 192, 0.8)" }}
              />
              <span style={{ color: "rgba(192, 192, 192, 0.8)" }}>Unread</span>
            </AccordionSummary>
            <AccordionDetails style={{ display: "grid", padding: 0 }}>
              {this.state.usersChatList?.map((user) => {
                const filtered = this.state.patientsProfile.find((index) => index.attributes.quickblox_id == user.user_id);
                if (user?.unread_messages_count > 0) {
                  return (
                    <UserChat
                      selectedQuickBloxId={this.state.selectedQuickBloxId}
                      isSelectedUser={this.state.isSelectedUser}
                      isShowTick={user?.unread_messages_count}
                      user={user}
                      createDialog={this.createDialog}
                      getMessagesList={this.state.getChatsList}
                      QB={this.QB}
                      userProfile={filtered}
                    />
                  )
                }
              })}
            </AccordionDetails>
          </Accordion>

          <Accordion
            style={{ boxShadow: "none",height: "calc(100vh - 350px)",overflow: 'auto' }}
            expanded={this.state.accordionOpen}
            data-testid={"readMessagesList"}
            onChange={this.handleChangeAccordionOpen}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon style={{ color: "rgba(192, 192, 192, 0.8)" }} />
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
              onClick={this.getListOfDialog.bind(this)}
            >
              <MailOutlineOutlinedIcon
                style={{ marginRight: 8, color: "rgba(192, 192, 192, 0.8)" }}
              />
              <span style={{ color: "rgba(192, 192, 192, 0.8)" }}>
                All Messages
              </span>
            </AccordionSummary>
            <AccordionDetails style={{ display: "grid", padding: 0 }}>
              {/* USERS LIST TEMPORY COMMENTED */}
              {this.state?.usersChatList?.map((user, index) => {
                const filtered = this.state.patientsProfile.find((index) => index.attributes.quickblox_id == user.user_id);
                if (user?.unread_messages_count === 0) {
                  return (
                    <UserChat
                      key={index}
                      testId="createDialog"
                      isShowTick={user?.unread_messages_count}
                      selectedQuickBloxId={this.state.selectedQuickBloxId}
                      isSelectedUser={this.state.isSelectedUser}
                      user={user}
                      createDialog={this.createDialog}
                      getMessagesList={this.state.getChatsList}
                      QB={this.QB}
                      userProfile={filtered}
                    />
                  )
                }
              })}
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    );
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <>
        <SideNavbar
          navigation={this.props.navigation}
          id={this.props.id}
          showStudy={true}
          showButtonChats
          showButtonNotification
        />
        <Dialog
          open={this.state.logoutFlag}
          maxWidth={"xs"}
          fullWidth
          aria-labelledby="responsive-dialog-title"
        >
          <PasswordDialogStyle>
            <DialogActions>
              <IconButton
                onClick={() => this.setState({ logoutFlag: false })}
                data-testid="logout-chat"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-playstation-x"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  stroke-width="2"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M12 21a9 9 0 0 0 9 -9a9 9 0 0 0 -9 -9a9 9 0 0 0 -9 9a9 9 0 0 0 9 9z" />
                  <path d="M8.5 8.5l7 7" />
                  <path d="M8.5 15.5l7 -7" />
                </svg>
              </IconButton>
            </DialogActions>
            <DialogTitle>
              <Typography className="dialog-title">
                Chat Session Expired
              </Typography>
            </DialogTitle>
            <DialogContent>
              <Typography className="dialog-para">
                Please Login Again
              </Typography>
            </DialogContent>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "20px",
              }}
            >
              <Button
                data-testid="handleLogout"
                style={{ width: "100%", margin: "30px" }}
                onClick={this.handleLogout.bind(this)}
                type="reset"
                variant="outlined"
                className="change-password-btn"
              >
                Yes
              </Button>
              <Button
                data-testid="logOut"
                type="reset"
                style={{ width: "100%", margin: "30px" }}
                onClick={() => this.setState({ logoutFlag: false })}
                className="change-password-btn"
                variant="outlined"
              >
                No
              </Button>
            </div>
          </PasswordDialogStyle>
        </Dialog>
        <ContainerBox>
          <Sidebar
            navigation={this.props.navigation}
            id={"task_list"}
            selected="patient"
          />
          {!this.state.isOpenAudioCall ? (
            <div style={{ width: "100vw" }}>
              <Grid style={{ height: "100%" }} container>
                {this.renderLeftChatList()}

               {
                (this.state.getChatsList.length > 0 || this.state.isChatOkay) && 
                <><Grid style={{ background: "white" }} item xs={8}>
                    <Grid item xs={12} style={{ marginTop: 30 }}>
                      <div
                        style={{
                          display: "flex",
                          height: 60,
                          justifyContent: "center",
                          alignItems: "center",
                          padding: "0 16px 16px 16px",
                          borderBottom: "1px solid lightgray",
                        }}
                      >
                        <Avatar
                          style={{ marginRight: 8 }}
                          alt="Remy Sharp"
                          src= {this.state.chatProfile}/>
                        <span
                          style={{
                            fontFamily: "Jost",
                            fontSize: "16px",
                            lineHeight: "26px",
                            fontWeight: 600,
                            color: "lightgray",
                            flex: 1,
                          }}
                        >
                          Conversation with{" "}
                          <span style={{ fontWeight: 700, color: "black" }}>
                            {this.state.receiverChatObj?.name}
                          </span>
                        </span>
                        <img
                          src={notiIcon}
                          style={{ marginRight: 16, cursor: "pointer" }}
                          width="30"
                          height="30"
                        ></img>
                        <img
                          src={callIcon}
                          data-test-id="audio-id"
                          onClick={this.handleOpenAudioCall}
                          style={{ marginRight: 16, cursor: "pointer" }}
                          width="30"
                          height="30"
                        ></img>
                      </div>
                    </Grid>

                    {/* content */}
                    <Grid
                      item
                      xs={12}
                      style={{
                        overflow: "scroll",
                        height: `calc(100vh - 245px)`,
                        padding: 16,
                        scrollbarWidth: "none",
                        display: "flex",
                        flexDirection: "column-reverse",
                      }}
                    >
                      {this.state.getChatsList.map((chat: any, index: number) => {
                        const currentMessageDate = new Date(chat?.created_at).toDateString();
                        const previousMessageDate = new Date(this.state.getChatsList[index + 1]?.created_at).toDateString();
                        const showDate = currentMessageDate !== previousMessageDate;
                        return (
                          <ChatItem
                            receiverId={this.state.receiverIdChat}
                            senderId={chat.sender_id}
                            message={chat.message}
                            senderInfo={this.state.loginInfo}
                            chatInfo={chat}
                            receiverInfo={this.state.receiverChatObj}
                            selectedFileName={this.state.selectedFileName}
                            selectedFileType={this.state.selectedFileType}
                            filePreviewUrl={this.state.filePreviewUrl}
                            selectedFilePreview={this.state.selectedFilePreview}
                            QB={this.QB}
                            filesResult={this.state.filesResult}
                            showDate={showDate}
                            index={index}
                            chatProfile={this.state.chatProfile}
                            />
                        );
                      })}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      style={{
                        height: this.chatInputHeight,
                        padding: "0px 16px 0px 16px",
                        display: "grid",
                      }}
                    >
                      <TextField
                        value={this.state.chatMessageVal}
                        onChange={this.handleChatMessageChange}
                        onKeyDown={this.handleKeyDown}
                        InputProps={{
                          endAdornment: (
                            <>
                              {this.state.isUploadDocLoading ? <CircularProgress size="30px" /> :
                                <>
                                  <MyAttachFileIcon
                                    style={{ marginRight: 16 }}
                                    onClick={this.attachFile.bind(this)} />
                                  <input
                                    id="fileInput"
                                    style={{ display: "none" }}
                                    onChange={this.handleFileChange}
                                    type="file"
                                    accept=".pdf,.jpg,.png,.jpeg,.svg,.WebP,.avif,.bmp,.mp4,.ogg,.webm,.3gp" />
                                  <MySendRoundedIcon
                                    onClick={this.handleSendMessage.bind(this)} />
                                </>}
                            </>
                          ),
                        }}
                        placeholder="Send message..." 
                        id="outlined-basic"
                        style={{}}
                        variant="outlined" />
                    </Grid>
                  </Grid></>
               }
              </Grid>
            </div>
          ) : (
            <>
              <Grid style={{ height: "100%" }} container>
                <Grid item xs={8} style={{ marginTop: 30 }}>
                  <div
                    style={{
                      display: "flex",
                      height: 60,
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "0 16px 16px 16px",
                      borderBottom: "1px solid lightgray",
                    }}
                  >
                    <Avatar
                      style={{ marginRight: 8 }}
                      alt="Remy Sharp"
                      src={this.state.chatProfile}
                    />
                    <span
                      style={{
                        fontFamily: "Jost",
                        fontSize: "16px",
                        lineHeight: "26px",
                        fontWeight: 600,
                        color: "lightgray",
                        flex: 1,
                      }}
                    >
                      Conversation with{" "}
                      <span style={{ fontWeight: 700, color: "black" }}>
                        {this.state.receiverChatObj?.name}
                      </span>
                    </span>
                    <Box display={"flex"} alignItems={"center"} width={"28%"}>
                      {!this.state.isOpenMic ? (
                        <MicOffOutlinedIcon
                          data-testid="handleMicOn"
                          onClick={this.handleMicOn}
                          style={webStyles.micBtnStyle}
                        />
                      ) : (
                        <MicNoneOutlinedIcon
                          data-testid="handleMicOff"
                          onClick={this.handleMicOff}
                          style={webStyles.micBtnStyle}
                        />
                      )}
                      <Typography
                        variant="subtitle1"
                        style={{
                          color: "lightgray",
                          fontWeight: 800,
                          paddingRight: "25px",
                        }}
                      >
                        Mic
                      </Typography>
                      <Button
                        data-testid="handleLeaveCall"
                        onClick={this.handleLeaveCall}
                        style={{
                          backgroundColor: "#A776FB",
                          color: "white",
                          border: "1px solid #A776FB",
                          borderRadius: "8px",
                          height: "50px",
                        }}
                      >
                        Leave
                      </Button>
                    </Box>
                  </div>
                  {/* Users Audio Call */}
                  <Grid>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "55%",
                        paddingTop: "15%",
                      }}
                    >
                      <Avatar
                        style={{
                          marginRight: 8,
                          height: "130px",
                          width: "130px",
                        }}
                        alt="Remy Sharp"
                        src={this.state.chatProfile}
                      />
                    </Box>

                    <Box
                      display={"flex"}
                      justifyContent={"flex-end"}
                      pr={"10%"}
                    >
                      <Avatar
                        style={{
                          marginRight: 8,
                          height: "130px",
                          width: "130px",
                        }}
                        alt="Remy Sharp"
                        src={this.state.profileImagePic}
                      />
                    </Box>
                  </Grid>
                </Grid>
                {/* Chats */}
                <Grid
                  item
                  xs={4}
                  style={{
                    overflowY: "auto",
                    height: "calc(100vh - 60px)",
                    padding: 16,
                    scrollbarWidth: "none",
                    display: "flex",
                    flexDirection: "column-reverse",
                    borderLeft: "1px solid lightgray",
                  }}
                >
                  <div>
                    <TextField
                      onKeyDown={this.handleKeyDown}
                      value={this.state.chatMessageVal}
                      onChange={this.handleChatMessageChange}
                      InputProps={{
                        endAdornment: (
                          <>
                            {this.state.isUploadDocLoading ? <CircularProgress size="30px" /> :
                              <>
                                <MyAttachFileIcon
                                  style={{ marginRight: 16 }}
                                  onClick={this.attachFile.bind(this)} />
                                <input
                                  style={{ display: "none" }}
                                  id="fileInput"
                                  type="file"
                                  onChange={this.handleFileChange}
                                  accept=".pdf,.jpg,.png,.jpeg,.svg,.WebP,.avif,.bmp,.mp4,.ogg,.webm,.3gp" />
                                <MySendRoundedIcon
                                  onClick={this.handleSendMessage.bind(this)} />
                              </>}
                          </>
                        ),
                      }}
                      style={{ width: '100%'}}
                      id="outlined-basic"
                      variant="outlined"
                      placeholder="Send message..." />
                  </div>
                  {this.state.getChatsList?.map((chat: any, index: number) => {
                  const currentMessageDate = new Date(chat?.created_at).toDateString();
                  const previousMessageDate =  new Date(this.state.getChatsList[index + 1]?.created_at).toDateString() 
                  const showDate = currentMessageDate !== previousMessageDate;
                    return (
                      <ChatItem
                        filePreviewUrl={this.state.filePreviewUrl}
                        selectedFileName={this.state.selectedFileName}
                        message={chat.message}
                        chatInfo={chat}
                        QB={this.QB}
                        senderId={chat.sender_id}
                        receiverId={this.state.receiverIdChat}
                        receiverInfo={this.state.receiverChatObj}
                        filesResult={this.state.filesResult}
                        senderInfo={this.state.loginInfo}
                        showDate={showDate}
                        selectedFilePreview={this.state.selectedFilePreview}
                        selectedFileType={this.state.selectedFileType}
                        index={index}
                        chatProfile={this.state.chatProfile}
                      />
                    );
                  })}
                </Grid>
              </Grid>
            </>
          )}
        </ContainerBox>
        <Dialog
          open={this.state.iOpenAcceptRejectPage}
          maxWidth={"xs"}
          fullWidth
          aria-labelledby="responsive-dialog-title"
        >
          <PasswordDialogStyle>
            <DialogTitle className="dialog-header">
              <Typography className="dialog-title">
                Incoming Call from {this.state.callingUser.name || "Unknown"}
              </Typography>
            </DialogTitle>
            <DialogContent>
              <Typography className="dialog-message">Would you like to accept?</Typography>
            </DialogContent>
            <DialogActions className="dialog-actions">
              <Button
                data-testid="handleRejectCall"
                style={{
                  width: "100%",
                  margin: "30px",
                  color: "white",
                  backgroundColor: "red",
                }}
                onClick={this.handleRejectCall.bind(this)}
                type="reset"
                variant="outlined"
                className="change-password-btn"
              >
                Decline
              </Button>
              <Button
                data-testid="handleAcceptCall"
                type="reset"
                style={{
                  width: "100%",
                  margin: "30px",
                  color: "white",
                  backgroundColor: "green",
                }}
                onClick={this.handleAcceptCall.bind(this)}
                className="change-password-btn"
                variant="outlined"
              >
                Accept
              </Button>
            </DialogActions>
          </PasswordDialogStyle>
        </Dialog>
        {
          this.state.isLoading && (
            <Box
              style={{
                display: 'flex',
                width: '100%',
                height: '100%',
                position: 'absolute',
                backgroundColor: '#00000054',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
              <CircularProgress />
            </Box>
          )
        }
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyles = {
  createButtonView: {
    display: "flex",
    justifyContent: "center",
    padding: "10px 0px",
    marginBottom: 20,
  },
  roomListContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  listItemContainer: {
    width: 300,
    border: "2px solid #ccc",
    borderRadius: 10,
    padding: 15,
    marginBottom: 20,
  },
  modalContainer: {
    display: "flex",
    flexDirection: "row",
    position: "absolute" as "absolute",
    top: "20%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: 300,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  },
  modalButtonView: {
    marginLeft: 10,
  },
  mutedChat: {
    backgroundColor: "lightgray",
  },
  micBtnStyle: {
    marginRight: 16,
    height: "30px",
    width: "30px",
    color: "lightgray",
    cursor: "pointer",
  },
};

const ContainerBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  flexGrow: 1,
});

const MyAttachFileIcon = styled(AttachFileIcon)({
  cursor: "pointer",
});

const MySendRoundedIcon = styled(SendRoundedIcon)({
  cursor: "pointer",
});
// Customizable Area End
