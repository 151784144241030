import React from "react";
// Customizable Area Start
import { Accordion, AccordionDetails, Typography, AccordionSummary, FormControlLabel, Box, Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Drawer, FormControl, Grid, Hidden, IconButton, InputAdornment, NativeSelect, Paper, TextField, styled, BoxProps, DrawerProps, Checkbox, Select, MenuItem } from '@material-ui/core';
import SideNavbarController, {
    Props, configJSON
} from "./SideNavbarController";
import { buttonCancel, headerLogo, headerUser, lockLogo, rightLogo } from "../../blocks/email-account-registration/src/assets";
import { callLogo, contactEmailLogo, contactPhoneLogo, dropdownLogo, editLogo, emailLogo, humanLogo, locationLogo, rightArrow, selectedLogo, userLogo, buttonChats, buttonNotifications } from "../../blocks/customisableuserprofiles2/src/assets";
import MenuIcon from '@material-ui/icons/Menu';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Visibility, VisibilityOff } from "@material-ui/icons";
import GenericFileUploadButton from "./GenericFileUploadButton.web";
import PhoneInput from 'react-phone-input-2'
// import 'react-phone-input-2/lib/style.css'
import { tick } from "../../blocks/appointmentmanagement/src/assets";
import { headerSecenodLogo, language } from "./assets";
// Customizable Area End

class SideNavbar extends SideNavbarController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    drawerStudy = () => {
        const { studyDrawerOpen, searchQuery } = this.state
        const filteredStudies = this.getFilteredStudies();
        const BoxView = Box as React.FC<BoxProps>
        return (
            <StudyModalStyle>
                {studyDrawerOpen && <React.Fragment>
                    <Container className="container" >
                        <Grid item xs={12} style={{ height: '30vh', overflowY: 'auto' }}>
                            <BoxView className="searchStudy" style={{ width: '95%' }}>
                                <TextField
                                    value={searchQuery}
                                    fullWidth
                                    variant="outlined"
                                    placeholder="Search Study Name"
                                    onChange={this.handleSearchChange}
                                />
                            </BoxView>
                            {filteredStudies?.map((item: any) => {
                                return (
                                    <BoxView style={{ width: '95%' }}>
                                        <Typography
                                            id="studyVal"
                                            className={`studyName ${this.state.selectedStudy === item.id ? 'selected' : ''}`}
                                            onClick={() => this.handleItemClick(item)}
                                        >
                                            {item.attributes.study_name}
                                            {this.state.selectedStudy === item.id && <img src={selectedLogo} alt="selectedLogo" className="selectLogo" />}
                                        </Typography>
                                    </BoxView>
                                )
                            })}
                        </Grid>
                    </Container>
                </React.Fragment>
                }
            </StudyModalStyle>
        )
    }

openNotification = () => {
    return (
        <NotificationStyle>
            <Container className="mainContainer">
                <div>
                    <IconButton
                        className="editLogo"
                        data-test-id="editLogo"
                        onClick={() => this.setState({ showCheckbox: !this.state.showCheckbox })}
                    >
                        <Typography style={{
                            fontWeight: 600,
                            fontSize: '12px',
                            textDecoration: 'underline',
                        }}>Select</Typography>
                    </IconButton>
                    {/* Render checkbox if showCheckbox is true */}
                </div>
                <div style={{ marginTop: '10px', padding: '10px 10px 10px 10px' }}>
                    {this.state.notificationResponce?.map((item: any, index: any) => (
                        <React.Fragment key={index}>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                {this.state.showCheckbox &&
                                    <FormControlLabel
                                        label=''
                                        control={
                                            <Checkbox
                                                style={{
                                                    borderRadius: '6.667px',
                                                    border: '1.667px solid #E6E6E6',
                                                    background: 'var(--White, #FFF)',
                                                }}
                                                name='check'
                                                value={this.state.checkedValue}
                                                icon={<span style={{ width: '4px', height: '4px', flexShrink: 0 }} />}
                                                checkedIcon={<img src={tick} />}
                                                checked={this.handleckedvalue(item)}
                                                onChange={(e) => this.handleCheckboxChange(item, e)}
                                            />
                                        }
                                    />
                                }
                                <div style={{ display: 'flex' }}>
                                    <div style={{ display: 'flex' }}>
                                        {!item.is_read && <div style={{
                                            backgroundColor: 'blue',
                                            width: '10px',
                                            height: '10px',
                                            borderRadius: '50%',
                                            marginRight: '8px',
                                            marginTop: '6px'
                                        }}></div>}
                                        <div>
                                            <Typography style={{
                                                fontWeight: 800,
                                                fontSize: '16px',
                                                textTransform: 'none',
                                                color: item.title === "Appointment Declined" ? "red" : item.title === "Appointment Accepted" ? "green" : "black"
                                            }}>{item.title}!</Typography>
                                            <Typography>{item.contents}</Typography>
                                            <Typography>{this.callFormatedDate(item.date)}</Typography>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Divider />
                        </React.Fragment>
                    ))}
                </div>
            </Container>
            {this.state.showCheckbox && this.state.notificationResponce?.length > 0 && (
                <div style={{
                    position: 'fixed',
                    marginTop: '10px',
                    // borderRadius: "20px",
                    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)",
                    padding: '10px',
                    bottom: 0,
                    width: "600px",
                    backgroundColor:'#ffff'
                }}>
                    <div style={{
                        display: 'flex', width: "100%",
                        justifyContent: 'space-between', alignItems: 'center',
                    }}>
                        <Button
                            style={{
                                fontWeight: 300,
                                fontSize: '12px',
                                textTransform: 'none',
                            }}
                            onClick={() => this.handleSelectAll()}
                        >Select All</Button>
                        <div style={{ marginRight: '15px' }}>
                            <Button
                                style={{
                                    fontWeight: 200,
                                    fontSize: '10px',
                                    textTransform: 'none',
                                }}
                                onClick={() => this.handleMarkAsRead()}
                            >Mark as Read</Button>
                            <Button
                                style={{
                                    fontWeight: 200,
                                    fontSize: '10px',
                                    textTransform: 'none',
                                }}
                                onClick={() => this.handleRemove()}
                            >Remove</Button>
                        </div>
                    </div>
                </div>
            )}
        </NotificationStyle>
    );
}



    passwordDialog = () => {
        const { oldPassword, newPassword, newConfirmPassword, oldPasswordError, newPasswordError, newConfirmPasswordError, } = this.state;

        return (
            <Dialog
                fullWidth
                maxWidth={'xs'}
                open={this.state.passwordDialogOpen}
                aria-labelledby="responsive-dialog-title" >
                <PasswordDialogStyle>
                    <DialogActions>
                        <IconButton data-test-id="cancel-password" onClick={() => this.handleClose("Change Password")} >
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-playstation-x" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M12 21a9 9 0 0 0 9 -9a9 9 0 0 0 -9 -9a9 9 0 0 0 -9 9a9 9 0 0 0 9 9z" /><path d="M8.5 8.5l7 7" /><path d="M8.5 15.5l7 -7" /></svg>
                        </IconButton>
                    </DialogActions>
                    <DialogTitle >
                        <Typography className="dialog-title">Change Password</Typography>
                    </DialogTitle>
                    <DialogContent>
                        <Typography className="dialog-para">
                            Please enter your new password to reset.
                        </Typography>
                        <Grid item xs={12}>
                            <TextField
                                type={this.state.showOldPassword ? 'text' : 'password'}
                                fullWidth
                                id="oldPassword"
                                variant="outlined"
                                placeholder="Enter Old Password"
                                value={oldPassword}
                                onChange={(e) => { this.handleOldPasswordChange(e.target.value) }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <img src={lockLogo} />
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {oldPassword.length > 0 ? (<IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => this.handleClickShowOldPassword()}
                                                data-test-id="toggleButton"
                                            >
                                                {this.state.showOldPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>) : null}
                                        </InputAdornment>
                                    ),
                                    className: "oldPswd"
                                }}
                                inputProps={{
                                    "data-test-id": 'oldPassword'
                                }}
                                error={oldPasswordError !== ''}
                                helperText={oldPasswordError}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                type={this.state.showNewPassword ? 'text' : 'password'}
                                fullWidth
                                variant="outlined"
                                placeholder="Enter New Password"
                                id="newPswd"
                                value={newPassword}
                                onChange={(e) => this.handleNewPasswordChange(e.target.value)}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <img src={lockLogo} />
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {newPassword.length > 0 ? (<IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => this.handleClickShowNewPassword()}
                                            >
                                                {this.state.showNewPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>) : null}
                                        </InputAdornment>
                                    ),
                                    className: "newPswd"

                                }}
                                inputProps={{
                                    "data-test-id": 'newPassword'
                                }}
                                error={newPasswordError !== ''}
                            />

                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                type={this.state.showNewConfirmPassword ? 'text' : 'password'}
                                fullWidth
                                variant="outlined"
                                placeholder="Confirm New Password"
                                id="cNewPswd"
                                value={newConfirmPassword}
                                onChange={(e) => this.handleNewConfirmPasswordChange(e.target.value)}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <img src={lockLogo} />
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {newConfirmPassword.length > 0 ? (<IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => this.handleClickShowNewConfirmPassword()}
                                            >
                                                {this.state.showNewConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>) : null}
                                        </InputAdornment>
                                    ),
                                    className: "cNewPswd"
                                }}
                                inputProps={{
                                    "data-test-id": 'confirmNewPassword'
                                }}
                                error={newConfirmPasswordError !== ''}
                                helperText={newConfirmPasswordError}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant="outlined" className="change-password-btn"
                                fullWidth
                                onClick={() => this.newSubmitPassWord()}
                                type='reset'
                                data-test-id="update-password"
                            >Update Password</Button>
                        </Grid>
                    </DialogContent>

                </PasswordDialogStyle>
            </Dialog>
        )
    }

    mailDialog = () => {
        const strongEmailRegex = configJSON.emailRegex;
        const { oldEmailAddress, newEmailAddress, newConfirmEmailAddress, oldEmailAddressError, newEmailAddressError, newConfirmEmailAddressError } = this.state
        return (
            <Dialog
                fullWidth
                maxWidth={'xs'}
                open={this.state.requestAdmin}
                aria-labelledby="responsive-dialog-title"
            >
                <MailDialogStyle>
                    <DialogActions>
                        <IconButton id='cancel-mailBtn' onClick={() => this.handleClose("Request Admin")}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-playstation-x" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M12 21a9 9 0 0 0 9 -9a9 9 0 0 0 -9 -9a9 9 0 0 0 -9 9a9 9 0 0 0 9 9z" /><path d="M8.5 8.5l7 7" /><path d="M8.5 15.5l7 -7" /></svg>
                        </IconButton>
                    </DialogActions>
                    <DialogTitle >
                        <Typography className="dialog-title">Change Email Request</Typography>
                    </DialogTitle>
                    <DialogContent>
                        <Typography className="dialog-para">
                            Please enter your new email to reset.
                        </Typography>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                id="oldEmailAddress"
                                variant="outlined"
                                placeholder="Enter Old Email"
                                value={oldEmailAddress}
                                onChange={(e) => { this.handleOldEmailAddress(e.target.value) }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <img src={emailLogo} />
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position="start">
                                            {strongEmailRegex.test(oldEmailAddress) ? (<img src={rightLogo} />) : null}
                                        </InputAdornment>
                                    ),
                                    className: "oldEmail"
                                }}
                                inputProps={{
                                    'data-test-id': "oldEmail"
                                }}
                                error={oldEmailAddressError !== ''}
                                helperText={oldEmailAddressError}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                placeholder="Enter New Email"
                                id="newEmailAddress"
                                value={newEmailAddress}
                                onChange={(e) => this.handleNewEmailAddress(e.target.value)}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <img src={emailLogo} />
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position="start">
                                            {strongEmailRegex.test(newEmailAddress) ? <img src={rightLogo} /> : null}
                                        </InputAdornment>
                                    ),
                                    className: "newEmail",
                                }}
                                inputProps={{
                                    'data-test-id': "newEmail"
                                }}
                                error={newEmailAddressError !== ''}
                                helperText={newEmailAddressError}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                placeholder="Confirm New Email"
                                id="newConfirmEmailAddress"
                                value={newConfirmEmailAddress}
                                onChange={(e) => this.handleNewConfirmEmailAddress(e.target.value)}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <img src={emailLogo} />
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position="start">
                                            {strongEmailRegex.test(newConfirmEmailAddress) ? <img src={rightLogo} /> : null}
                                        </InputAdornment>
                                    ),
                                    className: "cNewEmail"
                                }}
                                inputProps={{
                                    'data-test-id': "newConfirmEmail"
                                }}
                                error={newConfirmEmailAddressError !== ''}
                                helperText={newConfirmEmailAddressError}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant="outlined" className="change-password-btn"
                                fullWidth
                                data-test-id="send-request"
                                onClick={() => this.newSubmitEmailAddress()}
                            >Send Request</Button>
                        </Grid>
                    </DialogContent>

                </MailDialogStyle>
            </Dialog>
        )
    }

    drawerMain = () => {
        const { userDetails } = this.state
        let address = userDetails?.address; // Example string with extra comma at the end
        if (address) {
            address = address.trim().replace(/,\s*$/, '');
        }
        const BoxView = Box as React.FC<BoxProps>
        return (
            <MainStyle>
                <Container className="mainContainer" >
                    <Typography component={"span"} className="profile">Profile</Typography>
                    <IconButton className="editLogo" data-test-id="editLogo" onClick={() => this.setState({ editProfile: true })}>
                        <img src={editLogo} alt="editLogo" />
                    </IconButton>
                    <Typography className="userGroup">
                        <Typography>
                            <BoxView className="box">
                                <img src={this.state.profileImage || userLogo} alt="userLogo" style={{ borderRadius: "50%", verticalAlign: 'top', width: "50px", height: "50px" }} />
                                <BoxView className="nameGrp">
                                    <Typography className="userName" component={'span'}>{userDetails?.full_name}</Typography>
                                    <Typography className="userMail" component={'div'}>{userDetails?.email}</Typography>
                                </BoxView>
                            </BoxView>
                        </Typography>
                    </Typography>
                    <Divider />


                    <Typography className="fullNameGroup">
                        <BoxView className="box">
                            <img src={humanLogo} alt="humanLogo" className="humanLogo" />
                            <BoxView>
                                <Typography className="labelName">Full Name</Typography>
                                <Typography className="valueName">{userDetails?.full_name}</Typography>
                            </BoxView>
                        </BoxView>
                    </Typography>

                    <Typography className="emailGroup">
                        <BoxView className="box">
                            <img src={emailLogo} alt="emailLogo" className="emailLogo" />
                            <BoxView>
                                <Typography className="labelName">Email</Typography>
                                <Typography className="valueName">{userDetails?.email}</Typography>
                            </BoxView>
                        </BoxView>
                    </Typography>

                    <Typography className="PhoneGroup">
                        <BoxView className="box">
                            <img src={callLogo} alt="callLogo" className="callLogo" />
                            <BoxView>
                                <Typography className="labelName">Phone</Typography>
                                <PhoneInput value={userDetails?.full_phone_number}
                                country={'us'}
                                disabled={true}
                                inputStyle={{ border: "none", paddingLeft: "0px", color: '#494949' }}
                                disableDropdown={true}
                                buttonStyle={{ display: 'none' }}
                                />

                                {/* <Typography className="valueName">{userDetails?.full_phone_number}</Typography> */}
                            </BoxView>
                        </BoxView>
                    </Typography>

                    <Typography className="addressGroup">
                        <BoxView className="box">
                            <img src={locationLogo} alt="locationLogo" className="locationLogo" />
                            <BoxView>
                                <Typography className="labelName">Address</Typography>
                                <Typography className="valueName" style={{ width: '80%' }}>{address}</Typography>
                            </BoxView>
                        </BoxView>
                    </Typography>

                    <Divider />
                    <>
                        {this.state.detail.map((t: any, index: number) => (
                            <>
                                <BoxView className="listGroup">
                                    <img src={t.dImg} alt="d-image-logo" className="listLogo" />
                                    <Typography component={"span"} className="listName">{t.dName}</Typography>
                                    <img src={rightArrow} alt="rightArrow" className="rightLogo" data-test-id={t.id} onClick={() => this.handleClickOpen(t.dName)} />
                                    <Divider className="divider" />
                                </BoxView>
                            </>
                        ))}
                    </>


                </Container>
            </MainStyle>
        )
    }

    technicalHelp = () => {
        return (
            <HelpModalStyle>
                <Container className="helpContainer">
                    <IconButton className="cancelBtn" data-test-id="cancel-tech" onClick={() => this.handleClose("Technical Help")}>
                        <img src={buttonCancel} alt="buttonCancel" />
                    </IconButton>
                    <Typography component={"span"} className="editProfile">Technical Help</Typography>
                    <Paper style={{ paddingTop: '20px' }}>
                        {this.state.technicalHelpData?.map((item: any) => (
                            <React.Fragment key={item.id}>
                                <Accordion expanded={this.state.expanded === item.id} onChange={this.handleChange(item.id)}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1bh-content"
                                        id={item.id}
                                    >
                                        <Typography className="header">{item.attributes.question}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography className="para">
                                            {item.attributes.answer}
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Divider style={{ width: '94%', background: "#EBEBEB" }} />

                            </React.Fragment>
                        ))}
                    </Paper>

                </Container>
            </HelpModalStyle>
        )
    }

    drawerEdit = () => {
        const { userEmail, userEmailError, phoneNumber, phoneNumberError, fullName, fullNameError, userDetails, addressLine1, addressLine2, addressLine3 } = this.state
        const BoxView = Box as React.FC<BoxProps>
        return (
            <EditModalStyle>
                <Container className="mainEditContainer">
                    <Typography component={"span"} className="editProfile">Edit Profile</Typography>

                    <Box display={"flex"} flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"} marginY={4}>
                        <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
                            <img src={this.state.profileImage || userLogo} alt="userLogo" style={{ borderRadius: "50%", height: "50px", width: "50px", marginRight: 8 }} />
                            <Typography className="userEditName" component={'span'}>{userDetails?.full_name}</Typography>
                        </Box>
                        <GenericFileUploadButton text="Change profile picture" onUpload={this.onUpload} />
                    </Box>
                    {this.state.profileImageError && <p className="errorText">{this.state.profileImageError}</p>}

                    <BoxView>
                        <Typography className="labelName">Full Name</Typography>
                        <TextField
                            id="fullName"
                            className="fullNameEdit"
                            onChange={this.handleFullNameEdit}
                            value={fullName}
                            error={fullNameError !== ''}
                            inputProps={{ "data-test-id": "fullName-edit" }} />
                        <p className="errorText">{fullNameError}</p>
                    </BoxView>
                    <BoxView className="boxStyle">
                        <Typography className="labelName">Email Address</Typography>
                        <TextField
                            disabled
                            className="fullNameEdit"
                            id="userEmail"
                            value={userEmail}
                            onChange={this.handleUserNewEmail}
                            error={userEmailError !== ''}
                            inputProps={{ "data-test-id": "email-edit" }}
                        />
                        <p className="errorText">{userEmailError}</p>
                        <Typography className="request" data-test-id="request-admin" onClick={() => this.handleClickOpen('Request Admin')}>Request Admin</Typography>
                    </BoxView>

                    <BoxView
                    >
                        <Typography className="labelName">Phone Number</Typography>
                        <PhoneInput country={'us'}
                        inputProps={{ "data-test-id": "phoneNumber-edit1", "id": "phoneNumberEdit1" }}
                        inputStyle={{ width: "350px", borderRadius: "67px", height: "42px", paddingLeft: '20px' }}
                        value={phoneNumber}
                        onChange={phoneNumber => this.setState({ phoneNumber })}
                        buttonStyle={{ display: 'none' }}
                       />
                        <p className="errorText">{phoneNumberError}</p>

                    </BoxView>
                    <BoxView className="boxStyle">
                        <Typography className="labelName">Address</Typography>
                        <BoxView>
                            <TextField placeholder="Address Line 1" className="fullNameEdit" value={addressLine1} onChange={(e) => this.setState({ addressLine1: e.target.value })} />
                        </BoxView>
                        <BoxView className="boxStyle">
                            <TextField placeholder="Address Line 2" className="fullNameEdit" value={addressLine2} onChange={(e) => this.setState({ addressLine2: e.target.value })} />
                        </BoxView>
                        <BoxView className="boxStyle">
                            <TextField placeholder="Address Line 3" className="fullNameEdit" value={addressLine3} onChange={(e) => this.setState({ addressLine3: e.target.value })} />
                        </BoxView>
                    </BoxView>
                    <Button
                        data-test-id="update-detail"
                        variant="outlined"
                        className="updatebtn"
                        fullWidth
                        onClick={this.handleUpdateDetailsClick}
                    >Update Details</Button>
                </Container>
            </EditModalStyle>
        )
    }

    drawerContact = () => {
        const { selectError, selectedReason, contactFullName, contactFullNameError, contactUserEmailError, contactUserEmail, contactUserMessage } = this.state
        const BoxView = Box as React.FC<BoxProps>
        return (
            <ContactModalStyle>
                <Container className="contactContainer">
                    <img src={buttonCancel} alt="buttonCancel" data-test-id="cancel-contact" className="cancelBtn" onClick={() => this.handleClose("Contact Us")} />
                    <Typography component={"span"} className="contactUs">Contact Us</Typography>

                    <Typography className="talkText"> Talk with our Customer Support</Typography>
                    <Typography className="talkPara">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</Typography>

                    <Typography className="cntGrp">

                        <BoxView className="box">
                            <img src={contactPhoneLogo} alt="contactPhoneLogo" style={{ paddingTop: '10px' }} />
                            <BoxView className="pheGrp">
                                <Typography className="phone" component={'span'}>Phone</Typography>
                                <Typography className="pNumber" component={'div'}>+91 12345 12355</Typography>
                                <Typography className="avbleTime">Available 9:00 AM to 8:00 PM</Typography>
                            </BoxView>
                        </BoxView>

                        <BoxView className="box">
                            <img src={contactEmailLogo} alt="contactEmailLogo" style={{ paddingTop: '10px' }} />
                            <BoxView className="emGrp">
                                <Typography className="emailTxt" component={'span'}>Email</Typography>
                                <Typography className="supportEmail" component={'div'}>support@gmail.com</Typography>
                            </BoxView>
                        </BoxView>
                        <Typography className="sendText">Send your queries </Typography>
                    </Typography>
                    <>
                        <BoxView className="boxStyle">
                            <Typography className="cntLabelName">Full Name</Typography>
                            <TextField placeholder="John"
                                className="cntFullNameEdit"
                                onChange={this.handleContactFullName}
                                id="contactFullName"
                                value={contactFullName}
                                error={contactFullNameError !== ''}
                                inputProps={{
                                    "data-test-id": 'contact-fullname'
                                }}
                            />
                            <p className="errorText">{contactFullNameError}</p>
                        </BoxView>
                        <BoxView className="boxStyle">
                            <Typography className="cntLabelName">Email Address</Typography>
                            <TextField
                                value={contactUserEmail}
                                placeholder="johndeo@gmail.com"
                                className="cntFullNameEdit"
                                onChange={this.handleContactUserEmail}
                                error={contactUserEmailError !== ''}
                                inputProps={{
                                    "data-test-id": 'contact-email'
                                }}
                            />
                            <p className="errorText">{contactUserEmailError}</p>
                        </BoxView>

                        <BoxView className="boxStyle">
                            <Typography className="cntLabelName">Reason</Typography>

                            <FormControl
                                className="cntFullNameEdit"
                            >
                                <NativeSelect
                                    value={selectedReason}
                                    onChange={this.handleDropdownChange}
                                    name="age"
                                    className="cntFullNameEditSelect"
                                    inputProps={{ 'aria-label': 'age' }}
                                    data-test-id="select-reason"
                                >
                                    <option value=''>Enter Reason</option>
                                    <option value='10'>Example 1</option>
                                    <option value='20'>Example 2</option>
                                    <option value='30'>Example 3</option>
                                </NativeSelect>
                            </FormControl>
                            <p className="errorText">{selectError}</p>
                        </BoxView>

                        <BoxView className="boxStyle">
                            <Typography className="cntLabelName">Message</Typography>
                            <TextField
                                placeholder="Type your message"
                                className="cntFullNameEdit"
                                value={contactUserMessage}
                                onChange={e => this.setState({ contactUserMessage: e.target.value })}
                            />
                        </BoxView>

                        <Button className="submitCntBtn" fullWidth
                            data-test-id="contactSubmitBtn"
                            onClick={this.handleContactSubmitButton}
                        >Submit</Button>
                    </>
                </Container>
            </ContactModalStyle>
        )
    }

    termsDialog = () => {
        const { termsAndConditions } = this.state;
        const BoxView = Box as React.FC<BoxProps>
        return (
            <Dialog
                scroll="body"
                className="termsContainer"
                fullWidth
                maxWidth={'lg'}
                open={this.state.termsDialogOpen}
                aria-labelledby="responsive-dialog-title" >
                <TermsDialogStyle>
                    <DialogActions>
                        <IconButton data-test-id="cancel-terms" onClick={() => this.handleClose("Terms and Conditions")}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-playstation-x" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M12 21a9 9 0 0 0 9 -9a9 9 0 0 0 -9 -9a9 9 0 0 0 -9 9a9 9 0 0 0 9 9z" /><path d="M8.5 8.5l7 7" /><path d="M8.5 15.5l7 -7" /></svg>
                        </IconButton>
                    </DialogActions>
                    <DialogTitle >
                        <Typography className='termsText'>Terms and Condition</Typography>
                    </DialogTitle>
                    <DialogContent >
                        <BoxView className="box">
                            <div className="dialogContent" dangerouslySetInnerHTML={{ __html: termsAndConditions }} />
                        </BoxView>
                    </DialogContent>
                </TermsDialogStyle>
            </Dialog>
        )
    }
    logoutDailog = () => {
        return (

            <Dialog
                fullWidth
                maxWidth={'xs'}
                open={this.state.logoutFlag}
                aria-labelledby="responsive-dialog-title" >
                <PasswordDialogStyle>
                    <DialogActions>
                        <IconButton data-test-id="cancel-password"
                            onClick={() => this.setState({ logoutFlag: false })}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-playstation-x" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M12 21a9 9 0 0 0 9 -9a9 9 0 0 0 -9 -9a9 9 0 0 0 -9 9a9 9 0 0 0 9 9z" /><path d="M8.5 8.5l7 7" /><path d="M8.5 15.5l7 -7" /></svg>
                        </IconButton>
                    </DialogActions>
                    <DialogTitle >
                        <Typography className="dialog-title">Logout</Typography>
                    </DialogTitle>
                    <DialogContent>
                        <Typography className="dialog-para">
                            Are you sure Do you want to Logout ?
                        </Typography></DialogContent>
                    <div style={{ display: 'flex', margin: '20px', justifyContent: "space-between", }}>
                        <Button variant="outlined" className="change-password-btn"
                            style={{ width: '100%', margin: '30px' }}
                            onClick={() => this.handleLogout()}
                            type='reset'
                            data-test-id="deleteschedule"
                        >Yes</Button>
                        <Button variant="outlined" className="change-password-btn"
                            style={{ width: '100%', margin: '30px' }}
                            onClick={() => this.setState({ logoutFlag: false })}
                            type='reset'
                            data-test-id="cancelschedule"
                        >No</Button></div></PasswordDialogStyle>
            </Dialog>

        )
    }
    privacyDialog = () => {
        const { privacyPolicy } = this.state
        const BoxView = Box as React.FC<BoxProps>
        return (
            <Dialog
                scroll="body"
                className="privacyContainer"
                fullWidth
                maxWidth={'lg'}
                open={this.state.privacyDialogOpen}
                aria-labelledby="responsive-dialog-title" >
                <PrivacyDialogStyle>
                    <DialogActions>
                        <IconButton data-test-id="cancel-privacy" onClick={() => this.handleClose("Privacy Policies")}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-playstation-x" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M12 21a9 9 0 0 0 9 -9a9 9 0 0 0 -9 -9a9 9 0 0 0 -9 9a9 9 0 0 0 9 9z" /><path d="M8.5 8.5l7 7" /><path d="M8.5 15.5l7 -7" /></svg>
                        </IconButton>
                    </DialogActions>
                    <DialogTitle >
                        <Typography className='privacyText'>Privacy Policy</Typography>
                    </DialogTitle>
                    <DialogContent >
                        <BoxView className="box">
                            <div className="dialogContent" dangerouslySetInnerHTML={{ __html: privacyPolicy }} />
                        </BoxView>
                    </DialogContent>
                </PrivacyDialogStyle>
            </Dialog>
        )
    }

    conditionFunction = () => {
        if (this.state.editProfile) {
            return this.drawerEdit()
        } else if (this.state.techBarOpen) {
            return this.technicalHelp()
        } else if (this.state.contactBarOpen) {
            return this.drawerContact()
        } else if (this.state.open) {
            return this.drawerMain()
        }

    }
    // Customizable Area End
    render() {
        const BoxView = Box as React.FC<BoxProps>
        const { showStudy } = this.props
        return (
            // Customizable Area Start
            <ModalStyle>
                <Dialog open={this.state.tokenExpired}>
                    <p style={{ fontFamily: "Jost", fontSize: "23px", padding: '0 22px 10px 30px', fontStyle: "normal", fontWeight: 600, }}>Your session has expired, please login again.</p>
                    <Button onClick={this.handleCloseDialog} style={{ margin: '20px', textTransform: 'none', fontFamily: "Jost", fontSize: "17px", fontStyle: "normal", border: '2px solid rgb(160, 70, 251)', borderRadius: '8px' }}>
                        Ok
                    </Button>
                </Dialog>
                <Grid container style={{ borderBottom: '2px solid #A1A1A180', display: "flex" }}>
                    <Grid item xs={12} sm={6} md={6}>
                        <Grid container>
                            <Grid item xs={6} sm={6}>
                                {window.location.pathname == "/EmailAccountLoginBlock" ? <img src={headerLogo} alt="headerLogo" className="main-logo" /> : <img src={headerSecenodLogo} alt="headerLogo" className="main-logo" />}
                            </Grid>
                            <Grid item xs={6} sm={6} style={{ display: "flex", alignItems: "center", }}>
                                {showStudy &&
                                    <Typography component={'span'} className="studySelectGrp" >{this.state.selectedStudyName === "" ? "Select Study" : "Study : " + this.state.selectedStudyName}<img data-test-id="buttonStudy" onClick={this.handleStudyDrawerOpen} style={{ verticalAlign: 'middle', marginLeft: '10px' }} src={dropdownLogo} alt="dropdownLogoStudy" /></Typography>
                                }
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sm={6} className="menu-notification" >
                        <Box>
                            <img src={language} alt="language" width={33} height={33} style={{ cursor: 'pointer', }} onClick={this.handleLanguageSelected} />

                            {this.state.selectdPaper &&
                                <Grid style={{ backgroundColor: '#F9F9F9', border: '1px solid transparent', position: "fixed", boxShadow: '1px 2px 7px #9E9E9E', padding: '10px', borderRadius: '10px' }}>
                                    <div>
                                        <TextField
                                            value={this.state.selectedValue}
                                            onClick={this.handleClick}
                                            InputProps={{
                                                readOnly: true,
                                                disableUnderline: true,
                                                endAdornment: (
                                                    <InputAdornment position="end" onClick={this.handleClick} style={{ cursor: 'pointer' }}>
                                                        <ExpandMoreIcon
                                                            onClick={this.handleClick}
                                                            style={{
                                                                transform: this.state.isRotated ? 'rotate(180deg)' : 'rotate(0deg)',
                                                                transition: 'transform 0.3s ease',
                                                            }}
                                                        />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            style={{ minWidth: 120, border: '1px solid #E3E3E3', padding: '3px 10px 0 20px', borderRadius: '20px',cursor:'pointer' }}
                                        />

                                        {this.state.showDiv && (
                                            <div style={{backgroundColor: '#ffff', border: '1px solid #E3E3E3',  padding: '10px', borderRadius: '18px' }}>
                                                <Typography style={{padding:'5px'}} onClick={() => this.handleLanguageSelect('English')}>English</Typography>
                                                <Typography style={{padding:'5px'}} onClick={() => this.handleLanguageSelect('French')}>French</Typography>
                                                <Typography style={{padding:'5px'}} onClick={() => this.handleLanguageSelect('German')}>German</Typography>
                                                <Typography style={{padding:'5px'}} onClick={() => this.handleLanguageSelect('Spanish')}>Spanish</Typography>
                                            </div>
                                        )}
                                    </div>
                                </Grid>}

                        </Box>
                        <Box>
                            {this.props.showButtonChats && (
                                <IconButton onClick={() => this.props.navigation.navigate('Chat')}>
                                    {this.props.unreadCount > 0 && 
                                     <div
                                            style={{
                                                backgroundColor: 'red',
                                                width: '8px',
                                                height: '8px',
                                                borderRadius: '50%',
                                                marginRight: '-25px',
                                                marginBottom:'20px'
                                            }}
                                        ></div>
                                    } 
                                        <img src={buttonChats} alt="buttonChats" width={25} height={25} style={{ cursor: 'pointer' }}/>
                                </IconButton>
                            )}
                        </Box>
                        <Box>
                            {this.props.showButtonNotification && (
                                <>                                
                                    {this.state.notificationResponce?.some((item: any) => !item.is_read) && (
                                        <div
                                            style={{
                                                backgroundColor: 'red',
                                                width: '8px',
                                                height: '8px',
                                                borderRadius: '50%',
                                                marginLeft: '15px',
                                                marginBottom:'-5px'
                                            }}
                                        ></div>
                                    )}
                                    <img src={buttonNotifications} alt="buttonNotifications" width={25} height={25} style={{ cursor: 'pointer' }} onClick={() => this.handleNotificationDrawer()} />
                                </>
                            )}
                        </Box>
                        <Box>
                            <img src={this.state.profileImage || headerUser} alt="headerUser" className='headerLogo' style={{ height: "50px", width: "50px", borderRadius: "50%" }} />
                            <Typography component={"span"} className='userName'>{this.state.userDetails?.full_name}</Typography>
                            <IconButton data-test-id="drawer-btn" onClick={() => this.handleDrawerOpen()}>
                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-down" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M6 9l6 6l6 -6" /></svg>
                            </IconButton>
                        </Box>
                    </Grid>
                </Grid>

                {this.drawerStudy()}
                <DrawerStyle
                    anchor="right"
                    open={this.state.open}
                    onClose={this.handleDrawerClose}
                    style={{
                        width: 470
                    }}
                >
                    {this.conditionFunction()}
                </DrawerStyle>
                <DrawerStyle
                    anchor="right"
                    open={this.state.notificationDrawerFlag}
                    onClose={this.handleNotificationDrawerClose}
                    style={{
                        width: 470
                    }}
                >
                    {this.openNotification()}
                </DrawerStyle>
                {this.passwordDialog()}
                {this.mailDialog()}
                {this.termsDialog()}
                {this.privacyDialog()}
                {this.logoutDailog()}
            </ModalStyle>
            // Customizable Area End
        )
    }
}

// Customizable Area Start
const StudyModalStyle = styled((Box as React.FC<BoxProps>))({
    "& .selectLogo": {
        verticalAlign: 'middle',
        float: 'right',
    },
    "& .MuiOutlinedInput-input": {
        padding: 0,
        height: "38px",
        color: "#D4D3D3",
        fontFamily: "Jost",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 400,
        paddingLeft: '16px',
    },
    "& .container": {
        padding: '20px 30px',
        maxWidth: '558px',
        width: '100%',
        borderRadius: "8px",
        background: "#2F2F2F",
        boxShadow: "none",
        position: "absolute",
        top: "10.4%",
        zIndex: 100,
        left: "19.4%",
        "@media (max-width: 1366px)": {
            maxWidth: '500px',
            left: '25%',
        },
        "@media (max-width: 1199px)": {
            maxWidth: '400px',
            left: '28%',
        },
        "@media (max-width: 991px)": {
            maxWidth: '300px',
            left: '34%',
        },
        "@media (max-width: 691px)": {
            maxWidth: '200px',
        },
        "@media (max-height: 1366px)": {
            top: "auto",
        },
        "@media (max-height: 1199px)": {
            top: "auto",
        },
        "@media (max-height: 991px)": {
            top: "auto",
        },
        "@media (max-height: 600px)": {
            top: "auto",
        },
        "@media (max-height: 400px)": {
            top: "auto",
        },
        "@media (min-width: 1600px)": {
            // left: "16.8%",
        },
        "@media (min-width: 1920px)": {
            top: "auto",
        }
    },

    "& .searchStudy": {
        borderRadius: "4px",
        border: "1px solid #474747",
        height: "38px",
        cursor: 'pointer',
        color: "#D4D3D3",
        fontFamily: "Jost",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "22px"

    },
    "& .studyName.selected": {
        color: "#FFF",
        fontFamily: "Jost",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 600,
    },
    "& .studyName": {
        paddingTop: '16px',
        color: "#D4D3D3",
        fontFamily: "Jost",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 400,
        cursor: 'pointer',
    }

})
const DrawerStyle = styled(Drawer)<DrawerProps>({
    top: '76px !important',
    height: "calc(100vh - 90px)",
    "& .MuiDrawer-paper": {
        borderRadius: '10px 0 0 10px',
    },
    "& .MuiDrawer-paper, .MuiBackdrop-root": {
        top: '76px !important',
        height: "calc(100vh - 90px)",
    }
})
const ModalStyle = styled((Box as React.FC<BoxProps>))({
    position: 'sticky',
    top: 0,
    zIndex: 2222,
    backgroundColor: "#FFFFFF",
    "& .MuiMenu-paper": {
        backgroundColor: "red !important"
    },
    "& .menu-notification": {
        display: "flex",
        gap: 20,
        alignItems: "center",
        justifyContent: "end",
        "@media (max-width: 460px)": {
            marginLeft: "240px", marginTop: "-10px"
        },
        "@media (min-width: 320px) and (max-width: 460px)": {
            marginLeft: "100px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 10
        }
    },
    "& .studySelectGrp": {
        color: "#000",
        fontFamily: "Inter",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 500,
        cursor: 'pointer',
    },
    "& .main-logo": {
        padding: '15px',
        maxWidth: "322px"
    },
    "& .headerLogo": {
        verticalAlign: 'middle',
        marginRight: '12px'
    },
    "& .dropdownLogo": {
        verticalAlign: 'middle',
        cursor: 'pointer'
    },
    "& .userName": {
        color: "#000",
        fontFamily: "Jost",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 500
    }
})

const PasswordDialogStyle = styled(Box)({
    "& .passwordError": {
        maxWidth: "300px",
        fontSize: "12px",
        color: "#F44336",
        fontFamily: "Inter",
        marginTop: "5px",
        marginLeft: "14px"
    },
    "& .dialog-title": {
        color: "#000",
        fontSize: "28px",
        fontWeight: "bolder",
        fontFamily: "Jost",
    },
    "& .MuiDialogContent-root": {
        padding: '8px 40px'
    },

    "& .MuiDialogTitle-root": {
        padding: '0 40px'
    },
    "& .dialog-para": {
        fontFamily: "Jost",
        fontSize: "16px",
        color: "#5E5E5E",
        fontWeight: 400,
        fontStyle: "normal",
        width: '90%'
    },
    "& .change-password-btn": {
        height: '50px',
        color: '#000',
        fontFamily: 'Jost',
        fontSize: '14px',
        fontWeight: 'bolder',
        textTransform: 'capitalize',
        border: "2px solid #A046FB",
        marginBottom: '40px',
        borderRadius: "4px",
        marginTop: '32px',
        background: "#FFF",
        boxShadow: "0px 16px 32px 0px rgba(48, 49, 51, 0.10)"
    },
    "& .oldPswd": {
        fontFamily: 'Jost',
        height: '49px',
        marginTop: '24px',
    },
    "& .cNewPswd": {
        marginTop: '20px',
        height: '49px',
        fontFamily: 'Jost',
    },
    "& .newPswd": {
        height: '49px',
        marginTop: '20px',
        fontFamily: 'Jost',

    },
})

const MailDialogStyle = styled((Box as React.FC<BoxProps>))({
    "& .MuiDialogContent-root": {
        padding: '8px 40px'
    },
    "& .dialog-title": {
        color: "#000",
        fontSize: "28px",
        fontWeight: "bolder",
        fontFamily: "Jost",
    },
    "& .MuiDialogTitle-root": {
        padding: '0 40px'
    },
    "& .dialog-para": {
        color: "#5E5E5E",
        fontStyle: "normal",
        fontFamily: "Jost",
        fontSize: "16px",
        width: '90%',
        fontWeight: 400,
    },
    "& .change-password-btn": {
        height: '50px',
        color: '#000',
        textTransform: 'capitalize',
        fontFamily: 'Jost',
        fontSize: '14px',
        borderRadius: "4px",
        fontWeight: 'bolder',
        marginBottom: '40px',
        marginTop: '32px',
        border: "2px solid #A046FB",
        background: "#FFF",
        boxShadow: "0px 16px 32px 0px rgba(48, 49, 51, 0.10)",
    },

    "& .oldEmail": {
        fontFamily: 'Jost',
        marginTop: '24px',
        height: '49px',
    },
    "& .newEmail": {
        marginTop: '20px',
        height: '49px',
        fontFamily: 'Jost',
    },
    "& .cNewEmail": {
        marginTop: '20px',
        height: '49px',
        fontFamily: 'Jost',
    }
})
const drawerWidth = 470;
const MainStyle = styled((Box as React.FC<BoxProps>))({
    "& .mainContainer": {
        width: '470px',
        paddingTop: '40px',
        paddingLeft: '38px',
        paddingRight: '50px',
        marginBottom: '100px',
        "@media (max-width: 1366px)": {
            width: "300px"
        },
    },


    "& .drawerPaper": {
        width: drawerWidth,
        top: '67px'
    },
    "& .drawerHeader": {
        display: 'flex',
        alignItems: 'center',
        padding: "0 8px",
        justifyContent: 'flex-start',
    },

    "& .box": {
        display: 'flex'
    },
    "& .profile": {
        color: '#292929',
        fontFamily: 'Jost',
        fontSize: '24px',
        fontStyle: 'normal',
        fontWeight: 700,
    },
    "& .editLogo": {
        float: 'inline-end',
        cursor: 'pointer',
    },
    "& .userName": {
        color: "#484848",
        fontFamily: "Inter",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 600,
    },
    "& .userMail": {
        color: "#343434",
        fontFamily: "Inter",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 400
    },
    "& .userGroup": {
        marginTop: '30px',
        paddingBottom: '30px'
    },
    "& .nameGrp": {
        marginLeft: '15px',
    },
    "& .fullNameGroup": {
        paddingTop: '28px'
    },
    "& .humanLogo": {
        paddingRight: '16px'
    },
    "& .labelName": {
        color: "#8E8E8E",
        fontFamily: "Inter",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 400
    },
    "& .valueName": {
        color: "#494949",
        fontFamily: "Inter",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 500
    },
    "& .emailGroup": {
        paddingTop: '28px'
    },
    "& .emailLogo": {
        paddingRight: '16px'
    },

    "& .PhoneGroup": {
        paddingTop: '28px'
    },
    "& .addressGroup": {
        paddingTop: '28px',
        paddingBottom: '14px'
    },
    "& .locationLogo": {
        paddingRight: '16px'
    },
    "& .callLogo": {
        paddingRight: '16px'
    },
    "& .listName": {
        color: "#343434",
        fontFamily: "Inter",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 500,
        marginLeft: '14px'
    },
    "& .listGroup": {
        paddingTop: '18px'
    },
    "& .listLogo": {
        verticalAlign: 'bottom',
        height: "24px",
        width: "24px"
    },
    "& .rightLogo": {
        float: 'inline-end',
        cursor: 'pointer'
    },
    "& .divider": {
        marginTop: '18px'
    }
})
const NotificationStyle = styled((Box as React.FC<BoxProps>))({
    "& .mainContainer": {
        width: '600px',


    },


    "& .drawerPaper": {
        width: drawerWidth,
        top: '67px'
    },
    "& .drawerHeader": {
        display: 'flex',
        alignItems: 'center',
        padding: "0 8px",
        justifyContent: 'flex-start',
    },

    "& .box": {
        display: 'flex'
    },
    "& .profile": {
        color: '#292929',
        fontFamily: 'Jost',
        fontSize: '24px',
        fontStyle: 'normal',
        fontWeight: 700,
    },
    "& .editLogo": {
        float: 'inline-end',
        cursor: 'pointer',
        padding: '5px'
    },
    "& .userName": {
        color: "#484848",
        fontFamily: "Inter",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 600,
    },
    "& .userMail": {
        color: "#343434",
        fontFamily: "Inter",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 400
    },
    "& .userGroup": {
        marginTop: '30px',
        paddingBottom: '30px'
    },
    "& .nameGrp": {
        marginLeft: '15px',
    },
    "& .fullNameGroup": {
        paddingTop: '28px'
    },
    "& .humanLogo": {
        paddingRight: '16px'
    },
    "& .labelName": {
        color: "#8E8E8E",
        fontFamily: "Inter",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 400
    },
    "& .valueName": {
        color: "#494949",
        fontFamily: "Inter",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 500
    },
    "& .emailGroup": {
        paddingTop: '28px'
    },
    "& .emailLogo": {
        paddingRight: '16px'
    },

    "& .PhoneGroup": {
        paddingTop: '28px'
    },
    "& .addressGroup": {
        paddingTop: '28px',
        paddingBottom: '14px'
    },
    "& .locationLogo": {
        paddingRight: '16px'
    },
    "& .callLogo": {
        paddingRight: '16px'
    },
    "& .listName": {
        color: "#343434",
        fontFamily: "Inter",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 500,
        marginLeft: '14px'
    },
    "& .listGroup": {
        paddingTop: '18px'
    },
    "& .listLogo": {
        verticalAlign: 'bottom',
        height: "24px",
        width: "24px"
    },
    "& .rightLogo": {
        float: 'inline-end',
        cursor: 'pointer'
    },
    "& .divider": {
        marginTop: '18px'
    }
})
const EditModalStyle = styled((Box as React.FC<BoxProps>))({
    "& .mainEditContainer": {
        paddingTop: '40px',
        paddingLeft: '38px',
        paddingRight: '50px',
        maxWidth: '470px',
        width: '500px',
    },
    "& .MuiContainer-root": {
        paddingLeft: "39px",
        paddingRight: "38px",
    },

    "& .errorText": {
        color: 'red',
        fontSize: '14px',
        fontFamily: 'Jost'
    },

    "& .editDrawer": {
        width: drawerWidth,
        flexShrink: 0,
    },
    "& .drawerPaper": {
        width: drawerWidth,
    },
    "& .box": {
        display: 'flex'
    },
    "& .editProfile": {
        color: "#292929",
        fontFamily: "Jost",
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: 700
    },
    "& .editLogo": {
        float: 'inline-end',
        cursor: 'pointer',
    },
    "& .userEditName": {
        color: "#484848",
        fontFamily: "Inter",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 600,
    },
    "& .userGroup": {
        marginTop: '30px',
        paddingBottom: '30px'
    },
    "& .nameEditGrp": {
        marginLeft: '15px',
        marginTop: '8px',
        display: "flex",
        aligniItems: "center",
        justifyContent: "center",
        gap: "52px",
    },
    "& .fullNameGroup": {
        paddingTop: '28px',
    },
    "& .boxStyle": {
        paddingTop: '20px',
    },
    "& .labelName": {
        color: "#8E8E8E",
        fontFamily: "Inter",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 400,
        paddingBottom: '6px'
    },
    "& .react-tel-input": {
        // margin-top: 10px !important;
        // margin-left: 10px !important;
    }
    ,
    "& .flag-dropdown": {
        borderRadius: "20px 0 0 20px"
        //  background-color: rgb(240, 205, 159) !important;
    },
    "& .fullNameEdit": {
        color: "#494949",
        fontFamily: "Inter",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 500,
        borderRadius: "67px",
        border: "1px solid #E3E3E3",
        background: "rgba(255, 255, 255, 0.80)",
        maxWidth: '350px',
        width: '100%',
        "& .MuiInputBase-input": {
            padding: '12px 22px'
        },
        "& .MuiInput-underline:after": {
            borderBottom: "none"
        },
        "& .MuiInput-underline:before": {
            borderBottom: "none"
        }
    },

    '& .request': {
        color: "#A046FB",
        textAlign: "center",
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: "Jost",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 400,
        float: "right",
        paddingRight: "44px",
        cursor: 'pointer'

    },
    "& .updatebtn": {
        height: '50px',
        color: '#000',
        fontFamily: 'Jost',
        textTransform: 'capitalize',
        fontSize: '14px',
        fontWeight: 'bolder',
        marginBottom: '100px',
        borderRadius: "4px",
        border: "2px solid #A046FB",
        background: "#FFF",
        boxShadow: "0px 16px 32px 0px rgba(48, 49, 51, 0.10)",
        marginTop: '32px',
    },
})

const HelpModalStyle = styled((Box as React.FC<BoxProps>))({

    "& .helpContainer": {
        paddingTop: '40px',
        marginLeft: '16px',
        maxWidth: '450px',
        width: drawerWidth,
    },
    "& .MuiContainer-root": {
        paddingLeft: "20px",
        paddingRight: "38px",
    },

    "& .editDrawer": {
        width: drawerWidth,
        flexShrink: 0,
    },
    "& .drawerPaper": {
        width: drawerWidth,
    },
    "& .box": {
        display: 'flex'
    },
    "& .editProfile": {
        color: "#292929",
        fontFamily: "Jost",
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: 700
    },
    "& .cancelBtn": {
        float: 'right',
        marginRight: "-10px",
        cursor: 'pointer',
        marginTop: '-20px'
    },
    "& .MuiAccordionSummary-root ": {
        // display: "flex",
        padding: '0px',
        paddingRight: '20px',
    },
    "& .MuiPaper-elevation1": {
        boxShadow: 'none'
    },
    "& .MuiAccordionDetails-root": {
        padding: '0px'
    },
    "& .header": {
        color: "#484848",
        fontFamily: "Inter",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 500,
        // padding: '6px 0px'
    },
    "& .para": {
        color: "#616161",
        fontFamily: "Inter",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: '24px'
    },
    "& .MuiTypography-body1": {
        letterSpacing: '0'
    },
    "& .MuiAccordion-root:before": {
        width: '94%',
        background: "#EBEBEB",
    }
})

const ContactModalStyle = styled((Box as React.FC<BoxProps>))({
    "& .contactContainer": {
        paddingTop: '40px',
        paddingLeft: '48px',
        paddingRight: '50px',
        marginBottom: '30px',
        maxWidth: '470px',
        width: '100%',

    },
    "& .errorText": {
        fontFamily: 'Jost',
        fontSize: '14px',
        color: 'red',
    },
    "& .MuiContainer-root": {
        paddingLeft: "39px",
        paddingRight: "38px",
    },

    "& .editDrawer": {
        width: drawerWidth,
        flexShrink: 0,
    },
    "& .drawerPaper": {
        width: drawerWidth,
    },
    "& .box": {
        display: 'flex'
    },
    "& .contactUs": {
        color: "#292929",
        fontFamily: "Jost",
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: 700,
    },
    "& .cancelBtn": {
        float: 'right',
        cursor: 'pointer'
    },
    "& .talkText": {
        paddingTop: '10px',
        color: "#484848",
        fontFamily: "Inter",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 500
    },
    "& .talkPara": {
        paddingTop: '8px',
        color: "#616161",
        fontFamily: "Inter",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 400,
        width: '90%'
    },
    "& .cntGrp": {
        marginTop: '10px',

    },
    "& .pheGrp": {
        marginTop: '26px',
        marginLeft: '12px',
    },
    "& .phone": {
        color: "#8E8E8E",
        fontFamily: "Inter",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 400,
    },
    "& .pNumber": {
        color: "#484848",
        fontFamily: "Inter",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 600,
    },
    "& .avbleTime": {
        color: "#7D7D7D",
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: "Inter",
        fontSize: "10px",
        fontStyle: "normal",
        fontWeight: 400,
    },
    "& .emGrp": {
        marginTop: '12px',
        marginLeft: '12px',
    },
    "& .emailTxt": {
        color: "#8E8E8E",
        fontFamily: "Inter",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 400,
    },
    "& .supportEmail": {
        color: "#484848",
        fontFamily: "Inter",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 600
    },
    "& .sendText": {
        color: "#4D4D4D",
        fontFamily: "Inter",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 600,
        paddingTop: '40px'
    },

    "& .cntLabelName": {
        color: "#7D7D7D",
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: "Inter",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 400
    },

    "& .cntFullNameEdit": {
        marginTop: '4px',
        color: "#424141",
        fontFamily: "Inter",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 500,
        borderRadius: "67px",
        border: "1px solid #E3E3E3",
        background: "rgba(255, 255, 255, 0.80)",
        maxWidth: '350px',
        width: '100%',
        "& .MuiInputBase-input": {
            padding: '12px 22px'
        },
        "& .MuiInput-underline:after": {
            borderBottom: "none"
        },
        "& .MuiInput-underline:before": {
            borderBottom: "none"
        },

    },

    "& .cntFullNameEditSelect": {
        marginTop: '0px',
        color: "#424141",
        fontFamily: "Inter",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 500,
        borderRadius: "67px",
        border: "1px solid #E3E3E3",
        background: "rgba(255, 255, 255, 0.80)",
        maxWidth: '350px',
        width: '100%',
        "& .MuiInputBase-input": {
            padding: '12px 22px'
        },
        "& .MuiInput-underline:after": {
            borderBottom: "none"
        },
        "& .MuiInput-underline:before": {
            borderBottom: "none"
        },
    },

    "& .boxStyle": {
        paddingTop: '20px',
    },

    "& .MuiNativeSelect-select:focus": {
        marginTop: '0px',
        color: "#424141",
        fontFamily: "Inter",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 500,
        borderRadius: "67px",
        maxWidth: '350px',
        width: '100%',
        background: "none",
    },

    "& .submitCntBtn": {
        borderRadius: "53px",
        background: "#A046FB",
        color: "#FFF",
        textAlign: "center",
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: "Inter",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 700,
        textTransform: 'capitalize',
        marginTop: '30px',
        height: '48px',
        maxWidth: '356px',
        width: '100%'
    }


})

const TermsDialogStyle = styled((Box as React.FC<BoxProps>))({

    "& .MuiDialogContent-root": {
        padding: '8px 40px'
    },
    "& .MuiDialogTitle-root": {
        padding: '0 40px'
    },
    "& .termsContainer": {
        width: '100%',
        maxWidth: '1100px',
    },
    " & .termsText": {
        color: "#000",
        fontSize: "28px",
        fontFamily: "Jost",
        fontStyle: "normal",
        fontFeatureSettings: "'clig' off, 'liga' off",
        lineHeight: "38px",
        fontWeight: 700,
    },
    "& .dialogContent": {
        fontSize: "14px",
        color: "#3D3C3C",
        fontFamily: "Inter",
        fontWeight: 400,
        fontStyle: "normal",
        lineHeight: "26px"
    },
    "& .box": {
        // paddingTop: '60px',
    },
    "& .dialogContentPara": {
        fontFamily: "Inter",
        paddingTop: '20px',
        color: "#3D3C3C",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "26px",
        fontStyle: "normal",
        marginBottom: '10px'
    },
})

const PrivacyDialogStyle = styled((Box as React.FC<BoxProps>))({
    "& .MuiDialogContent-root": {
        padding: '8px 40px'
    },
    "& .MuiDialogTitle-root": {
        padding: '0 40px'
    },
    "& .privacyContainer": {
        width: '100%',
        maxWidth: '1100px',
    },
    " & .privacyText": {
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: "Jost",
        color: "#000",
        fontSize: "28px",
        lineHeight: "38px",
        fontStyle: "normal",
        fontWeight: 700,
    },
    "& .dialogContent": {
        fontFamily: "Inter",
        fontSize: "14px",
        fontWeight: 400,
        color: "#3D3C3C",
        fontStyle: "normal",
        lineHeight: "26px"
    },
    "& .box": {
        // paddingTop: '60px',
    },

    "& .dialogContentPara": {
        color: "#3D3C3C",
        fontFamily: "Inter",
        paddingTop: '20px',
        fontSize: "14px",
        lineHeight: "26px",
        marginBottom: '10px',
        fontStyle: "normal",
        fontWeight: 400,

    },



})

export default SideNavbar;